//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  )
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature:nth-child(1) {
  @include card(
    $card-details-background-colour: rgba($brand-primary, 0.9),
    $card-heading-colour: white,
    $card-image-background-colour: $_blue,
    $card-hover-details-background-colour: $_blue,
    $card-hover-heading-colour: white,
    $card-hover-summary-colour: white
      );
  @include card-text-overlay;
}

.homeFeatures .homeFeature:nth-child(2) {
  @include card(
    $card-details-background-colour: rgba($brand-primary, 0.9),
    $card-heading-colour: white,
    $card-image-background-colour: $_red,
    $card-hover-details-background-colour: $_red,
    $card-hover-heading-colour: white,
    $card-hover-summary-colour: white
);
  @include card-text-overlay;
}

.homeFeatures .homeFeature:nth-child(3) {
  @include card(
    $card-details-background-colour: rgba($brand-primary, 0.9),
    $card-heading-colour: white,
    $card-image-background-colour: $_green,
    $card-hover-details-background-colour: $_green,
    $card-hover-heading-colour: white,
    $card-hover-summary-colour: white
);
  @include card-text-overlay;
}

// // Choose your card style for home feature cards
// .homeFeatures .homeFeature {
//   @include card(
//     $card-text-align: center,
//     $card-gap-width: 2px,
//     $card-heading-colour: white,
//     $card-hover-details-background-colour: rgba(black, 0.5),
//     $card-image-background-colour: black,
//     $card-hover-image-opacity: 0.8,
//     $card-hover-heading-colour: white,
//     );
//   @include card-text-over-image(
//     $card-text-over-image-darken-image-amount: 0.2,
//     $card-text-over-image-tint-colour: black,
//     $card-text-over-image-show-summary-on-hover: false,  
//   );
// }

// .homeFeatures .homeFeature:nth-child(2) {
//   @include card(
//     $card-text-align: center,
//     $card-gap-width: 0,
//     $card-heading-colour: white,
//     $card-hover-details-background-colour: rgba($_blue, 0.5),
//     $card-image-background-colour: $_blue,
//     $card-hover-image-opacity: 0.8,
//     $card-hover-heading-colour: white,
//     );
//   @include card-text-over-image(
//     $card-text-over-image-darken-image-amount: 0,
//     $card-text-over-image-tint-colour: $_blue,
//     $card-text-over-image-show-summary-on-hover: false,  
//   );
// }

// .homeFeatures .homeFeature:nth-child(3) {
//   @include card(
//     $card-text-align: center,
//     $card-gap-width: 0,
//     $card-heading-colour: white,
//     $card-hover-details-background-colour: rgba($_orange, 0.5),
//     $card-image-background-colour: $_orange,
//     $card-hover-image-opacity: 0.8,
//     $card-hover-heading-colour: white,
//     );
//   @include card-text-over-image(
//     $card-text-over-image-darken-image-amount: 0,
//     $card-text-over-image-tint-colour: $_orange,
//     $card-text-over-image-show-summary-on-hover: false,  
//   );
// }

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 3,
  $prefab: 2
);

// Specify a card layout for each individual feed
.homeFeedevents .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 1,
    $prefab: 1
  );
}

.homeFeednews .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 2
  );
}

.homeFeedBox3 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 1,
    $prefab: 1
  );
  .feedItem {
    @include card;
    @include card-basic;
  }
}

// Choose your card style for home feed cards
.homeFeednews .feedList .feedItem, .homeFeedevents .feedList .feedItem  {
  @include card(
    $card-heading-font-size: $font-size-h5,
    $card-details-background-colour: transparent,
    $card-hover-details-background-colour: transparent,
    $card-image-opacity: 1,
    $card-hover-image-opacity: 1,
    $card-heading-colour: white,
    $card-hover-heading-colour: white,
    $card-hover-image-scale: 1.1,
    );
  @include card-basic(
  );
}

// .homeFeedevents .feedList .feedItem {
//   @include card (
//     $card-gap-width: 0,
//     $card-details-background-colour: transparent,
//     $card-hover-details-background-colour: transparent,
//     $card-hover-heading-colour: white,
//     $card-summary-colour: white,
//     $card-details-padding: 0,
//     $card-hover-image-scale: 1.1,
//     $card-hover-image-opacity: 1,
//     $card-hover-summary-colour: white,
//     $card-summary-font-size: 1.125em,
//     $card-footer-on: false,
//     );
//   @include card-text-overlay (
//     $card-text-overlay-show-summary-on-hover: false,
//     $card-text-overlay-summary-enabled: true,
//     $card-text-overlay-details-max-width: 500px,
//     $card-text-overlay-details-position-y: bottom,
//     $card-text-overlay-details-position-x: left,
//     );
// }

//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card(
    $card-heading-font-size: $font-size-h5,
    $card-details-background-colour: transparent,
    $card-hover-details-background-colour: transparent,
    $card-image-opacity: 1,
    $card-hover-image-opacity: 1,
    $card-hover-heading-colour: white,
    $card-hover-image-scale: 1.1,
    );
  @include card-basic(
  );
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}