// Add flourish to Carousel heading
// .carouselSlideHeading a {
//     @include highlighter(
//         $colour: white,
//         $border-radius: 3px,
//     );
// }
// Reduce width of features slightly
// .homeFeatures {
//     max-width: 1190px;
// }
// Fancy image manipulation for features
// .home-feature-image-link img {
//     filter: grayscale(100%);
//     mix-blend-mode: multiply;
// }

// Useful mixin for before and after dividers
@mixin beforeafter {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    overflow: hidden;
}

// Sticky header - nav
.pageHeader.sticky nav.menuMain {
  margin-top: $spacer;
}


// Add divider to imapct top/bottom
.homefeaturecategory-homeboximpactstats {
    position: relative;
    padding-top: 10rem;
    margin-top: -10rem;
    z-index: 0;
    &:after {
        @include beforeafter;
        height: calc((60 / 1440) * 100vw);
        top: -1px;
        background-image: url($assets-path + "curve.svg");
        background-size: 102%;
        transform: scaleX(-1);
    }
}

.pageFooterWrapper {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((60 / 1440) * 100vw);
        top: calc(((-60 / 1440) * 100vw) + 1px);
        background-image: url($assets-path + "grey-dark-curve.svg");
        background-size: 102%;
        transform: scaleX(-1);
    }
}

.homeFeedsWrapper {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((60 / 1440) * 100vw);
        top: calc(((-60 / 1440) * 100vw) + 1px);
        background-image: url($assets-path + "orange-curve.svg");
        background-size: 102%;
    }
}


.homeFeatures {
    z-index: 1;
    position: relative;
}
// Close up letter spaing in intro
.homeIntro * {
    letter-spacing: -0.05em;
}
// Events feed full width
// .homeFeedevents {
//     padding-top: 0;
//     padding-bottom: 0;
//     footer.postFooterWrapper {
//         display: none !important;
//     }
//     .feedsTitle {
//         position: absolute;
//         top: $spacer * 5;
//         left: calc((100vw - 1230px) / 2);
//         z-index: 2;
//         color: white;
//         z-index: 3;
//         @media (max-width: map-get($breakpoints, xl)) {
//             left: 0;
//         }
//         @media (max-width: map-get($breakpoints, lg)) {
//             top: $spacer * 3;
//         }
//     }
//     .feedList {
//         max-width: 100%;
//         margin: 0;
//         .feedItem {
//             margin: 0;
//             .feedTitle a {
//                 @include highlighter(
//                     $colour: white,
//                     $border-radius: 3px,
//                     );
//             }
//             .feedItemDetailsWrapper {
//                 margin-left: calc(((100vw - 1230px) / 2) + 15px);
//                 margin-bottom: $spacer * 3;
//                 z-index: 2;
//                 @media (max-width: map-get($breakpoints, xl)) {
//                     margin-left: 15px;
//                     width: calc(100% - 30px) !important;
//                 }
//                 @media (max-width: map-get($breakpoints, sm)) {
//                     margin-top: -$spacer;
//                     p {
//                         color: $grey-dark !important;
//                     }
//                 }
//             }
//             &:before {
//                 @media (min-width: map-get($breakpoints, sm)) {
//                     content: "";
//                     background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 20%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0) 65%);
//                     position: absolute;
//                     top: 0;
//                     left: 0;
//                     bottom: 0;
//                     right: 0;
//                     width: 100%;
//                     height: 100%;
//                     z-index:1;
//                 }
//             }
//         }
//     }
// }
// 
// // JK - Trying to wrestle this custom component into shape
// @media (max-width: map-get($breakpoints, sm)) {
//   .homeFeedevents .feedsTitle {
//     top: auto;
//     position: relative;
//     color: $body-colour;
//   }
// }

// YVN - Event feed edit
.homeFeedevents .feedItem:not(.cardNoBanner) {
    .feedTitle a {
        @include highlighter(
            $background-colour: $_blue,
            $colour: white,
            $border-radius: 3px,
            );
    }
    .feedItemDetailsWrapper {
        margin-top: -40px;
        z-index: 1;
    }
}

.homeFeedevents .feedItem.cardNoBanner {
    .feedTitle a {
        @include highlighter(
            $background-colour: $_blue,
            $colour: white,
            $border-radius: 3px,
            );
    }
}


// News feed add flourish
.homeFeednews {
    flex: unset !important;
    width: 100%;
    .feedList {
        .feedItem:not(.cardNoBanner) {
            .feedTitle a {
                @include highlighter(
                    $colour: white,
                    $border-radius: 3px,
                    );
            }
            .feedItemDetailsWrapper {
                margin-top: -40px;
                z-index: 1;
            }
        }
        .feedItem.cardNoBanner {
            .feedTitle a {
                @include highlighter(
                    $colour: white,
                    $border-radius: 3px,
                    );
            }
        }
    }
}
// Footer stuff
.footerBox {
    a {
        text-decoration: none !important;
    }
    &.footerBox2 {
        font-size: 0.875em;
    }
}
// Quick giving button
.quickGivingPanel {
    .button.donateBtn {
        background-color: $donate-colour;
        color: white;
    }
}
// Add flourish on post listing boxes
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem):not(.cardNoBanner) {
    h2 a {
        @include highlighter(
            $colour: white,
            $border-radius: 3px,
            );
    }
    .listedPostText {
        margin-top: -40px;
        z-index: 1;
    }
}
.listedPost.cardNoBanner {
    h2 a {
        color: $white !important;
        @include highlighter(
            $colour: white,
            $border-radius: 3px,
            );
    }
}
// Adjust mobile view
// @media (max-width: 1024px) {
//     .carouselSlideDetail {
//         padding-bottom: $spacer * 3;
//     }
// }

// Add line under header on pages without a banner
body.page-no-banner .pageHeader {
    border-bottom: 1px solid rgba(black, 0.1);
}

.homefeaturecategory-homeboxquickgiving .quickGivingPanel .formQuestion.donationAmount {
    border-radius: 3px;
}


body.homepage:not(.subsite) .pageFooterWrapper {
    margin: 0 auto;
}

.appealPostPage .commentsListWrapper,
.appealPostPage .postCommentsAlt {
    display: none;
}

// 569025 Embed mailchimp form
div#mc_embed_signup {
  .mc-field-group {
    margin: 20px 20px 20px 0;
    display: flex;
    flex-direction: column;
  }
  input#mc-embedded-subscribe {
    height: 38px;
    padding: 0 1em;
    background-color: $_blue;
    color: #fff;
    border-color: $_blue;
    &:hover {
      background-color: $_navy;
      border-color: $_navy;
    }
  }
}

// 610921 Change social media icons colour at footer
.pageFooter .socialIcons a:before {
    color: #ffffff;
}
.footerBox .socialIcons {
    margin-left: -12px;
}
