//
// Environment
//


//
// Config values
//

$platform-name: 'irishrefugeecouncil';


//
// Colours
//

$_orange: #f09300;
$_red: #b1401d;
$_blue: #0078b5;
$_green: #4f9ba0;
$_navy: #024976;

$brand-primary: $_orange;
$brand-secondary: $_blue;

$donate-colour: $_red;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;





//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 980px;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo: 'logo.png';
$logo-width: 200px;
$logo-height: 135px;
$logo-absolute: true; // true if we want the logo to vertically intersect with the menu


//
// Typography
//

$font-family-base: 'Open Sans', sans-serif;
$font-size-base: 1.125rem;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

$headings-font-family: Arial, sans-serif;
$headings-margin-top: 1.5em !default; // This will only affect headings within post content
$headings-letter-spacing: -0.05em;
$headings-colour: $_navy;


// Links
$link-colour: $_blue;
$link-hover-decoration: none;

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em !default;
$btn-padding-y: .6em !default;
$btn-font-weight: $font-weight-bold;
$btn-text-transform: uppercase;
$btn-background-colour: $_navy;
$btn-border-radius: 3px;

// Donate button

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $_navy; // A colour, or 'brand'
$social-icons-hover-colour: brand; // A colour, or 'brand'

// Header specific

// Footer specific social icons


//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-heading-font-size: $font-size-h4;
$card-heading-colour: $_navy;
$card-border-radius: 3px;
$card-footer-on: true;
$card-footer-background-colour: $white;
$card-min-width: 280px;
// Card text overlay

// Card text over

// Card side by side

// Card hover state
$card-hover-image-opacity: .7 !default;


//
// Menu admin
//
$menu-admin-enabled: false;


//
// Header
//


// Header content
// $header-content-margin-bottom: 0;

// Tagline

// Main call to action

// Search

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//

$header-sticky-enabled: true;
$header-sticky-background-colour: $white;
$header-sticky-box-shadow: 0 0 10px rgba($black, 0.1);
$header-sticky-logo: $logo;
$header-sticky-logo-width: $logo-width * 0.75;
$header-sticky-logo-height: $logo-height * 0.75;
$header-sticky-menu-admin-enabled: false;
$header-sticky-search-enabled: false;
$header-sticky-social-icons-enabled: false;
$header-sticky-cta-btns-enabled: false;
$header-sticky-header-content-margin-top: $spacer * 0.25;
$header-sticky-header-content-margin-bottom: $spacer * 0.25;
$header-sticky-nav-margin-bottom: $spacer;


//
// Navigation
//
$nav-background-colour: transparent;


// Top level items
$nav-top-level-item-colour: $_navy;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-colour: $brand-primary;
$nav-top-level-item-hover-background-colour: transparent;

// Submenus
$nav-submenu-background-colour: white;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);
$nav-submenu-item-colour: $grey-dark;
$nav-submenu-border-radius: 3px;

// Burger button

// Nav normal
$nav-normal-margin-bottom: 0;
$nav-normal-align-items: right; // left, center, right


//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//
$carousel-max-width: 100%;
$carousel-heading-font-size: $font-size-h3;
$carousel-heading-colour: white;
$carousel-details-background-colour: rgba($_green, 0.8);
// $carousel-read-more-enabled: false;
$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-details-margin-y: $spacer * 2;
$carousel-details-position-y: bottom; // top, center, bottom
$carousel-summary-colour: white;
$carousel-summary-font-size: 1.125em;
$carousel-details-max-width: 500px;
$carousel-image-overlay: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 20%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0) 65%); // Add a colour value or gradient here
$carousel-details-border-radius: 3px;
$carousel-read-more-font-size: 1.125rem;


// Carousel controls (left-right buttons)
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-margin-x: 1rem;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-icon-colour: white;
$carousel-controls-icon-font-size: 1.5em;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $_green;
$carousel-mobile-heading-colour: white;
$carousel-mobile-summary-colour: white;

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-max-width: $container-max-width;
$home-intro-background-colour: white;
// $home-intro-margin-top: -3rem;
$home-intro-margin-top: 0;
$home-intro-padding-y: $spacer * 3;
$home-intro-colour: $_navy;



//
// Home features
//
$home-features-padding-top: 0;
$home-features-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations



//
// Impact stats
//
$impact-stats-background-colour: $_navy;
$impact-stats-padding-y: $spacer * 8;


// Heading
$impact-stats-heading-margin-bottom: $spacer * 1;

// Individual stat

// Figure
$impact-stats-figure-colour:$brand-primary;

// Summary


//
// Home feeds
//


// Feeds title

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//

$footer-background-colour: #333333;
$footer-boxes-count: 3;
$footer-prefab: 6;
$footer-link-colour: $brand-primary;
$footer-margin-y: #{$spacer * 6};
// Footer admin links

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: white;
$newsletter-heading-font-size: $font-size-h4;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 2;


//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts

// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//
$blockquote-border-radius: 3px;
$blockquote-colour: white;



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//
$donation-form-frequency-tabs: true; // Have you added the DonationTarrifsAndTabs.js custom layout?


// Donation amounts


//
// Quick giving panel
//
$quick-giving-background-colour: $brand-primary;
$quick-giving-donation-amount-background-colour: rgba(white, 0.6);



//
// Home quick giving panel
//
$home-quick-giving-type: overlay; // simple, adjacent or overlay
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-donation-amount-background-colour: rgba(white, 0.6);
$home-quick-giving-donate-btn-colour: white;
$home-quick-giving-heading-colour: white;


// Adjacent homepage quick giving

// Overlay homepage quick giving
$home-quick-giving-type-overlay-items-side: right; // If type is overlay, which side do you want the copy and donation items?
$home-quick-giving-type-overlay-gradient: linear-gradient(
  to right,
  rgba($_orange, 0) 20%,
  rgba($_orange, 0.85) 70%
);


//
// Totalisers
//



//
// Comments
//
$comment-add-image-enabled: false;
$comment-add-video-enabled: false;


//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$shop-enabled: true; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Departments list

// Product-price

// Product post


//
// Subsite
//


// Subsite logo

// Subsite nav


//
// Cookie consent
//
